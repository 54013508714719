<template>
    <div class="task">
        <v-container class="my-5">
            <v-layout row>
                <v-dialog v-model="confirmPopup" max-width="400px">
                    <v-card>
                        <v-card-title>
                            {{ popupText }}
                        </v-card-title>
                        <v-card-text>
                            <v-btn
                                color="green"
                                class="white--text mr-4"
                                @click.stop="executeConfirmedAction"
                            >
                                TAK
                            </v-btn>
                            <v-btn
                                color="gray"
                                class="dark--text"
                                @click.stop="hidePopup"
                            >
                                NIE
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="deleteAttachmentPopup" max-width="450px">
                    <v-card>
                        <v-card-title>
                            Czy na pewno chcesz usunąć załącznik?
                        </v-card-title>
                        <v-card-text>
                            <v-btn
                                color="green"
                                class="white--text mr-4"
                                @click.stop="deleteAttach"
                            >
                                TAK
                            </v-btn>
                            <v-btn
                                color="gray"
                                class="dark--text"
                                @click.stop="deleteAttachmentPopup = false"
                            >
                                NIE
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-flex md2 class="mr-2">
                    <v-card min-height="50">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">{{
                                    signs
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Znaki</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                    <v-alert
                        dense
                        outlined
                        type="error"
                        v-if="copyLate"
                        class="mt-3"
                    >
                        Spóźnienie {{ lateCopy }}h
                    </v-alert>
                    <v-btn
                        color="blue"
                        class="white--text"
                        @click="(showCopyLate = true), (addCopyLate = true)"
                        v-if="copyLate"
                    >
                        Edytuj spóźnienie
                    </v-btn>
                </v-flex>
                <v-flex md3>
                    <v-card min-height="50">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">
                                    {{ dateCp ? dateCp.split("T")[0] : "" }}
                                    {{ correctDate(dateCp) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{
                                        role !== "copywriter" &&
                                        role !== "korektor"
                                            ? "Deadline dla copywritera"
                                            : "Deadline"
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-flex>
                <v-flex md3 class="mx-2" v-if="role === 'administrator' || role === 'moderator'">
                    <v-card min-height="50">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">
                                    {{ date ? date.split("T")[0] : "" }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    >Deadline</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-flex>
                <v-flex
                    md2
                    class="mx-2"
                    v-if="role === 'copywriter' && orderType === 0"
                >
                    <v-card min-height="50">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="headline mb-1">
                                    {{ signs !== 0 ? rate : copyRate }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    stawka netto/1000 zzs
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-flex>
                <v-flex md2 class="mx-2">
                    <div
                        class="d-flex align-center"
                        v-if="
                            role === 'korektor' ||
                            role === 'administrator' ||
                            role === 'moderator'
                        "
                    >
                        <v-flex>
                            <v-btn
                                color="green"
                                class="mr-2 white--text text-center"
                                @click.stop="modalToCheck = true"
                                v-if="
                                    (status == 'Poprawione' &&
                                        role === 'korektor' &&
                                        correctorSend) ||
                                    (status == 'Do sprawdzenia' &&
                                        role === 'korektor' &&
                                        correctorSend)
                                "
                            >
                                Wyślij teksty
                            </v-btn>

                            <v-btn
                                color="green"
                                class="mr-2 white--text text-center"
                                @click.stop="modalSend = true"
                                v-if="(status == 'Sprawdzone' || status == 'Ponownie poprawione') && (role === 'administrator' || role === 'moderator')"
                            >
                                Wyślij teksty
                            </v-btn>

                            <v-dialog v-model="modalSend" max-width="400px">
                                <v-card>
                                    <v-card-title>
                                        Wysyłka zadania
                                    </v-card-title>
                                    <v-card-text>
                                        <VueEditor
                                            v-model="sendContent"
                                            class="mb-4"
                                        />
                                        <v-checkbox v-if="this.status != 'Ponownie poprawione'" v-model="invoice" label="Wystaw fakturę"></v-checkbox>
                                        <v-btn
                                            color="green"
                                            class="white--text mr-4"
                                            @click.stop="
                                                sendText(id)
                                            "
                                            :loading="isMailSent"
                                        >
                                            Wyślij
                                        </v-btn>
                                        <v-btn
                                            color="gray"
                                            class="dark--text"
                                            @click.stop="modalSend = false"
                                        >
                                            Anuluj
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="modalToCheck" max-width="400px">
                                <v-card>
                                    <v-card-title>
                                        Czy na pewno zadanie jest gotowe?
                                    </v-card-title>
                                    <v-card-text>
                                        <v-btn
                                            color="green"
                                            class="white--text mr-4"
                                            @click.stop="
                                                ended(id, 'Do sprawdzenia')
                                            "
                                            :loading="isMailSent"
                                        >
                                            TAK
                                        </v-btn>
                                        <v-btn
                                            color="gray"
                                            class="dark--text"
                                            @click.stop="modalToCheck = false"
                                        >
                                            NIE
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-flex>
                        <v-flex>
                            <v-btn
                                color="green"
                                class="mr-12 white--text text-center"
                                @click.stop="modalFixed = true"
                                v-if="
                                    status == 'Poprawione' &&
                                    role === 'korektor' &&
                                    correctorSend
                                "
                            >
                                Wyślij poprawkę
                            </v-btn>
                            <v-dialog v-model="modalFixed" max-width="400px">
                                <v-card>
                                    <v-card-title>
                                        Na pewno zadanie jest gotowe?
                                    </v-card-title>
                                    <v-card-text>
                                        <v-btn
                                            color="green"
                                            class="white--text mr-4"
                                            @click.stop="
                                                ended(id, 'Poprawione')
                                            "
                                            :loading="isMailSentFixed"
                                        >
                                            TAK
                                        </v-btn>
                                        <v-btn
                                            color="gray"
                                            class="dark--text"
                                            @click.stop="modalFixed = false"
                                        >
                                            NIE
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-flex>
                        <v-flex>
                            <v-btn
                                color="red"
                                class="mr-2 white--text"
                                @click.stop="toFix(id)"
                                v-if="
                                    (status == 'Do sprawdzenia' &&
                                        role === 'korektor') ||
                                    (status == 'Poprawione' &&
                                        role === 'korektor')
                                "
                            >
                                Do poprawy
                            </v-btn>
                        </v-flex>
                        <v-flex>
                            <v-btn
                                color="primary"
                                @click="endTaskKorektor"
                                class="mr-12 white--text text-center"
                                v-if="((status == 'Do sprawdzenia') && role === 'korektor' && !correctorSend)"
                            >
                                Zamknij zadanie
                            </v-btn>

                            <v-btn
                                color="primary"
                                @click="endTaskKorektorChecked"
                                class="mr-12 white--text text-center"
                                v-if="((status == 'Poprawione') && role === 'korektor' && !correctorSend)"
                            >
                                Zamknij zadanie
                            </v-btn>
                        </v-flex>
                    </div>

                    <!-- Rating popup -->

                    <v-dialog v-model="showRatingPopup" max-width="400px">
                        <v-card>
                            <v-card-title>
                                <v-flex>
                                    <span v-if="!taskRate"
                                        >Jak ocenisz wykonanie zadania?</span
                                    >
                                    <span v-else
                                        >Czy chcesz edytować ocenę?</span
                                    >
                                    <v-btn
                                        icon
                                        @click="showRatingPopup = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-card-title>
                            <div v-if="taskRate">
                                <span>Aktualna ocena: {{ taskRate }}/10 </span>
                            </div>
                            <div class="single-task__flex">
                                <span>Fatalnie</span>
                                <custom-radio
                                    v-model="selectedNumber"
                                    :items="numbers"
                                ></custom-radio>
                                <span>Wzorowo</span>
                            </div>
                            <v-list-item two-line>
                                <v-textarea
                                    v-model="ratingText"
                                    label="Feedback dla copywritera"
                                    clearable
                                ></v-textarea>
                            </v-list-item>
                            <p v-if="rateErrors" class="error">
                                {{ rateErrors }}
                            </p>
                            <v-card-text>
                                <v-btn
                                    color="green"
                                    class="white--text"
                                    @click="addRating"
                                    :loading="isRatingAdded"
                                >
                                    Gotowe
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                    <!-- latecopy popup -->

                    <v-dialog v-model="showCopyLate" max-width="400px">
                        <v-card>
                            <v-card-title>
                                <v-flex>
                                    <span v-if="!addCopyLate"
                                        >Czy copywriter się spóźnił?</span
                                    >
                                    <v-btn icon @click="showCopyLate = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-flex>
                                <v-text-field
                                    v-model="lateCopy"
                                    type="number"
                                    v-if="addCopyLate"
                                    label="Godziny spóźnienia"
                                    clearable
                                ></v-text-field>
                            </v-card-title>

                            <v-card-text v-if="!addCopyLate">
                                <v-btn
                                    color="green"
                                    class="white--text mr-2"
                                    @click="showCopyLate = false"
                                    :loading="isRatingAdded"
                                >
                                    Nie
                                </v-btn>
                                <v-btn
                                    color="red"
                                    class="white--text"
                                    @click="addCopyLate = true"
                                    :loading="isRatingAdded"
                                >
                                    Tak
                                </v-btn>
                            </v-card-text>
                            <v-card-text v-if="addCopyLate">
                                <v-btn
                                    color="green"
                                    class="white--text mr-2"
                                    @click="copyLateAdd"
                                    :loading="isRatingAdded"
                                >
                                    Gotowe
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                    <EditTask
                        class="ml-auto mt-2"
                        :id="this.id"
                        v-if="
                            role == 'korektor' &&
                            (status == 'Poprawione' ||
                                status == 'Do sprawdzenia')
                        "
                    />
                    <div
                        class="d-flex align-center"
                        v-if="role === 'copywriter'"
                    >
                        <v-btn
                            color="red"
                            class="mr-2 white--text"
                            @click.stop="taskRejected(id)"
                            v-if="status == 'Oczekujące'"
                        >
                            Odrzuć
                        </v-btn>
                        <v-btn
                            color="green"
                            class="mr-2 white--text"
                            @click.stop="takeTask(id)"
                            v-if="status == 'Oczekujące'"
                        >
                            Przyjmij
                        </v-btn>
                        <v-btn
                            color="green"
                            class="mr-2 white--text"
                            @click.stop="showPopup('Czy zadanie jest na pewno gotowe?', id, done)"
                            v-if="status == 'Przyjęte'"
                        >
                            Tekst gotowy
                        </v-btn>
                        <v-btn
                            color="green"
                            class="mr-2 white--text"
                            @click.stop="showPopup('Czy zadanie zostało poprawione?', id, fixed)"
                            v-if="status == 'Do poprawki'"
                        >
                            Tekst poprawiony
                        </v-btn>
                    </div>
                </v-flex>
                <v-flex md1 row>
                    <EditTask
                        class="ml-auto mt-2 mr-5"
                        :id="this.id"
                        v-if="role !== 'copywriter' && role !== 'korektor'"
                    />
                    <RemoveTask
                        v-if="role !== 'copywriter' && role !== 'korektor'"
                        class="ml-auto mt-2"
                        :taskId="id"
                    />
                </v-flex>
            </v-layout>
            <v-layout row class="my-4 mt-6" v-if="role === 'administrator' || role === 'moderator'">
                <v-card>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="mt-3">{{
                                clientEmail
                            }}</v-list-item-title>
                            <v-list-item-subtitle>Email klienta</v-list-item-subtitle
                            >
                            <v-btn
                                type="button"
                                small
                                color="indigo"
                                dark
                                text
                                v-clipboard:copy="clientEmail"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <v-card
                    class="ml-4"
                    min-height="50"
                    v-if="role === 'administrator' || role === 'moderator'"
                >
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-select
                                v-if="role == 'administrator' || role == 'moderator'"
                                :items="allStatusAdmin"
                                v-model="status"
                                @change="changedStatus"
                            ></v-select>
                            <v-select
                                v-else
                                :items="allStatus"
                                v-model="status"
                                @change="changedStatus"
                            ></v-select>
                            <v-list-item-subtitle
                                >Status</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                
            </v-layout>
            <v-layout row class="my-4">
                <v-flex md8>
                    <v-card
                        height="600"
                        class="scroll mr-10 py-6 px-6 text-left"
                    >
                        <h2 class="text-left mb-4">{{ name }}</h2>
                        <div v-html="description"></div>
                        <div
                            text
                            v-for="(attachment, index) in files"
                            :key="index"
                            class="mb-3 d-flex mt-4 mx-8 align-center"
                        >
                            <a :href="attachment.path">
                                {{ attachment.originalName }}
                            </a>
                            <v-btn
                                v-if="
                                    role === 'administrator' ||
                                    role === 'moderator'
                                "
                                small
                                class="ml-5"
                                @click="deleteAttachment(attachment._id)"
                            >
                                X
                            </v-btn>
                        </div>
                        <v-form
                            ref="form"
                            class="d-flex align-baseline"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        >
                            <v-file-input
                                id="file"
                                type="file"
                                v-on:change="onAddNewFile"
                                label="Dodaj załącznik"
                            ></v-file-input>
                            <v-btn
                                small
                                class="ml-4"
                                @click="sendFile"
                                :loading="isLoaded"
                                :disabled="!file"
                            >
                                Dodaj
                            </v-btn>
                        </v-form>
                    </v-card>
                </v-flex>

                <v-flex md4>
                    <v-card height="600" class="scroll pa-3">
                        <Attachment
                            :taksId="this.id"
                            :status="this.status"
                            :oldStatus="this.lastLog"
                        />

                        <div class="rating" v-if="taskRate">
                            <v-divider></v-divider>
                            <p class="rating__top">Wykonane zadanie</p>
                            <p>Ocena: {{ taskRate }}/10</p>
                            <p v-if="taskDescription">
                                Komentarz: {{ taskDescription }}
                            </p>
                        </div>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout row class="my-4"> </v-layout>
            <v-layout>
                <v-flex>
                    <v-card class="pa-4">
                        <Comments ref="comments" />
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout
                row
                class="my-4"
                v-if="role === 'administrator' || role === 'moderator' || role === 'korektor'"
            >
                <v-flex md12>
                    <Logs :id="$route.params.id" :createdAt="createdAt" :createdBy="createdBy"/>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import io from "socket.io-client";
import EditTask from "@/components/molecules/EditTask.vue";
import RemoveTask from "@/components/molecules/RemoveTask.vue";
import CustomRadio from "@/components/molecules/CustomRadio.vue";
import Comments from "@/components/organisms/Comments.vue";
import Attachment from "@/components/organisms/Attachments.vue";
import Logs from "@/components/organisms/Logs.vue";
import { VueEditor } from "vue2-editor";

const token = localStorage.getItem('user');

export default {
    name: "Task",
    components: {
        Comments,
        EditTask,
        Attachment,
        Logs,
        RemoveTask,
        CustomRadio,
        VueEditor
    },
    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        userID() {
            return this.$store.getters.getUserID;
        },
        rate() {
            let copyRatee = this.copyRate / (this.signs / 1000);
            return copyRatee.toFixed(2);
        },
    },
    data() {
        return {
            numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            sendContent: "<p>Dzień dobry,</p><p>w załączniku przesyłamy zamówione treści. </p><p>Jeśli pojawią się pytania, służymy pomocą. </p>",
            invoice: true,
            selectedNumber: "",
            ratingText: "",
            isMailSent: false,
            isMailSentFixed: false,
            filesKorektor: null,
            filesKorektorFix: null,
            lastLog: null,
            lateCopy: null,
            isLoaded: false,
            id: this.$route.params.id,
            files: null,
            file: null,
            socket: io(this.url),
            text: null,
            modalToCheck: false,
            modalFixed: false,
            signs: null,
            name: null,
            status: "Ocze",
            copywriterId: null,
            pmId: null,
            taskRate: null,
            taskDescription: null,
            korektorId: null,
            description: null,
            correctorSend: null,
            characters: null,
            orderType: null,
            priceCopy: null,
            priceFull: null,
            client: null,
            clientEmail: null,
            copywriter: null,
            feedback: null,
            date: new Date().toISOString(),
            dateCp: new Date().toISOString(),
            tasks: [],
            clients: [],
            copywriters: [],
            menu: false,
            copyRate: null,
            copyLate: null,
            rateErrors: null,
            ratingFlag: false,
            addCopyLate: false,
            showCopyLate: false,
            showRatingPopup: false,
            isRatingAdded: false,
            confirmPopup: false,
            deleteAttachmentPopup: false,
            popupText: '',
            confirmedAction: null,
            confirmedId: null,
            attachmentId: null,
            createdAt: null,
            createdBy: null,
            modalSend: false,
            allStatusAdmin: [
                { text: "Szkic"},
                { text: "Oczekujące" },
                { text: "Odrzucone" },
                { text: "Przyjęte" },
                { text: "Do sprawdzenia" },
                { text: "Do poprawki" },
                { text: "Sprawdzone" },
                { text: "Poprawione" },
                { text: "Ponownie poprawione" },
                { text: "Zakończone" },
                { text: "Anulowane" },
            ],
            allStatus: [
                { text: "Oczekujące" },
                { text: "Odrzucone" },
                { text: "Przyjęte" },
                { text: "Do sprawdzenia" },
                { text: "Do poprawki" },
                { text: "Sprawdzone" },
                { text: "Poprawione" },
                { text: "Ponownie poprawione" },
                { text: "Zakończone" },
                { text: "Anulowane" },
            ],
        };
    },
    methods: {
        copyLateAdd() {
            this.$axios
                .patch(`${this.url}api/tasks/copylate`, {
                    id: this.id,
                    lateCopy: this.lateCopy,
                }, { headers: { 'x-access-token': token }})
                .then(() => {
                    this.showCopyLate = false;
                })
                .catch((err) => {
                    throw Error(err);
                });
        },
        addRating() {
            if (!this.selectedNumber) {
                this.rateErrors = "Musisz ocenić zadanie";
                return;
            }
            this.isRatingAdded = true;

            if (this.taskRate) {
                this.$axios
                    .put(`${this.url}api/rating`, {
                        id: this.id,
                        rate: this.selectedNumber,
                        description: this.ratingText,
                    }, { headers: { 'x-access-token': token }})
                    .then(() => {
                        this.isRatingAdded = false;
                        this.showCopyLate = false;
                        this.showRatingPopup = false;
                        this.$router.push(
                            `/${
                                this.$store.getters.getUserRole
                            }/${new Date().toISOString().substr(0, 7)}`
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                return;
            }

            this.$axios
                .post(`${this.url}api/rating`, {
                    rate: this.selectedNumber,
                    description: this.ratingText,
                    copy: this.copywriterId,
                    evaluator: this.userID,
                    task: this.id,
                    date: new Date().toISOString().substr(0, 10),
                }, { headers: { 'x-access-token': token }})
                .then(() => {
                    console.log("wykonalo");
                    this.changedStatus(this.status);
                    this.isRatingAdded = false;
                    this.showRatingPopup = false;
                    this.$router.push(
                        `/${
                            this.$store.getters.getUserRole
                        }/${new Date().toISOString().substr(0, 7)}`
                    );
                });
        },
        getRating() {
            this.$axios
                .get(`${this.url}api/rating/${this.$route.params.id}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    const { rate, description } = resp.data;
                    this.taskRate = rate;
                    this.taskDescription = description;
                    this.selectedNumber = rate;
                    this.ratingText = description;
                    console.log(rate);
                })
                .catch((err) => console.log(err));
        },
        correctDate(date) {
            const newDate = new Date(date);
            return `${newDate.getHours()}:${
                newDate.getMinutes() < 9
                    ? "0" + newDate.getMinutes()
                    : newDate.getMinutes()
            }`;
        },
        done() {
            this.$axios
                .put(`${this.url}api/tasks/pick`, {
                    copywriterId: localStorage.getItem("id"),
                    id: this.confirmedId,
                    korektorId: this.korektorId,
                    pmId: this.pmId,
                    status: "Do sprawdzenia",
                    date: new Date().toISOString(),
                    userId: this.userID,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
        hidePopup() {
            this.confirmPopup = false;
            this.popupText = '';
            this.confirmedId = null;
            this.confirmedAction = null;
        },
        executeConfirmedAction() {
            this.confirmedAction();
            this.hidePopup();
        },
        showPopup(text, id, confirmedAction){
            this.confirmPopup = true;
            this.popupText = text;
            this.confirmedId = id;
            this.confirmedAction = confirmedAction;
        },
        checked(id) {
            this.$axios
                .put(`${this.url}api/tasks/pick`, {
                    copywriterId: this.copywriterId,
                    id: id,
                    korektorId: this.korektorId,
                    pmId: this.pmId,
                    status: "Sprawdzone",
                    date: new Date().toISOString(),
                    userId: this.userID,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
        sendText(id) {

            let doneFlag = true;
            let file = null;

            console.log(this.status);
            if(this.status == 'Ponownie poprawione'){
                doneFlag = false;
                file = this.filesKorektorFix;
            }else{
                file = this.filesKorektor;
            }
            //  doneFlag ? this.filesKorektor : this.filesKorektorFix

            this.$axios
                .put(`${this.url}api/tasks/send`, {
                    copywriterId: this.copywriterId,
                    id: id,
                    pmId: this.pmId,
                    status: "Zakończone",
                    userId: this.userID,
                    isFixed: true,
                    clientName: this.client,
                    client: this.clientEmail,
                    filesToSend: file,
                    sendContent: this.sendContent,
                    send: this.invoice,
                    removeLink: doneFlag
                }, { headers: { 'x-access-token': token }})
                .then(() => {
                    this.modalFixed = false;
                    this.modalSend = false;
                    this.isMailSentFixed = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.isMailSentFixed = false;
                });
        },
        ended(id, tasktStatus) {
            if (tasktStatus === "Do sprawdzenia") {
                if (this.filesKorektor.length > 0) {
                    this.isMailSent = true;

                    if (
                        this.role != "moderator" &&
                        this.role != "administrator"
                    ) {
                        this.showRatingPopup = true;
                        this.showCopyLate = true;
                        this.ratingFlag = true;
                    }

                    this.$axios
                        .put(`${this.url}api/tasks/pick`, {
                            copywriterId: this.copywriterId,
                            id: id,
                            korektorId: this.korektorId,
                            pmId: this.pmId,
                            status: "Zakończone",
                            date: new Date().toISOString(),
                            userId: this.userID,
                            isFixed: false,
                            client: this.clientEmail,
                            clientName: this.client,
                            filesToSend: this.filesKorektor,
                        }, { headers: { 'x-access-token': token }})
                        .then(() => {
                            this.modalToCheck = false;
                            this.isMailSent = false;
                        })
                        .catch((err) => {
                            this.isMailSent = false;
                            console.log(err);
                        });
                }
            } else if (tasktStatus === "Poprawione") {
                if (this.filesKorektorFix.length > 0) {
                    this.isMailSentFixed = true;

                    this.showRatingPopup = true;
                    this.ratingFlag = true;

                    this.$axios
                        .put(`${this.url}api/tasks/pick`, {
                            copywriterId: this.copywriterId,
                            id: id,
                            korektorId: this.korektorId,
                            pmId: this.pmId,
                            status: "Zakończone",
                            date: new Date().toISOString(),
                            userId: this.userID,
                            isFixed: true,
                            clientName: this.client,
                            client: this.clientEmail,
                            filesToSend: this.filesKorektorFix,
                        }, { headers: { 'x-access-token': token }})
                        .then(() => {
                            this.modalFixed = false;
                            this.isMailSentFixed = false;
                        })
                        .catch((err) => {
                            console.log(err);
                            this.isMailSentFixed = false;
                        });
                }
            }
        },
        fixed() {
            this.$axios
                .put(`${this.url}api/tasks/pick`, {
                    copywriterId: localStorage.getItem("id"),
                    korektorId: this.korektorId,
                    pmId: this.pmId,
                    id: this.confirmedId,
                    status: "Poprawione",
                    date: new Date().toISOString(),
                    userId: this.userID,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
        toFix(id) {
            this.$axios
                .put(`${this.url}api/tasks/pick`, {
                    copywriterId: this.copywriterId,
                    korektorId: this.korektorId,
                    pmId: this.pmId,
                    id: id,
                    status: "Do poprawki",
                    date: new Date().toISOString(),
                    userId: this.userID,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
        taskRejected(id) {
            this.$axios
                .put(`${this.url}api/tasks/pick`, {
                    copywriterId: this.odrzucone,
                    id: id,
                    korektorId: this.korektorId,
                    pmId: this.pmId,
                    status: "Odrzucone",
                    date: new Date().toISOString(),
                    userId: this.userID,
                }, { headers: { 'x-access-token': token }})
                .then(() => this.$router.go(-1))
                .catch((err) => {
                    console.log(err);
                });
        },
        takeTask(id) {
            this.$axios
                .put(`${this.url}api/tasks/pick`, {
                    copywriterId: localStorage.getItem("id"),
                    korektorId: this.korektorId,
                    pmId: this.pmId,
                    id: id,
                    status: "Przyjęte",
                    date: new Date().toISOString(),
                    userId: this.userID,
                }, { headers: { 'x-access-token': token }})
                .catch((err) => {
                    console.log(err);
                });
        },
        getAttachKorektor() {
            this.$axios
                .get(this.url + "api/uploads/korektor/" + this.$route.params.id, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.filesKorektor = resp.data;
                });
        },
        getAttachKorektorFix() {
            this.$axios
                .get(
                    this.url +
                        "api/uploads/korektor-fix/" +
                        this.$route.params.id, { headers: { 'x-access-token': token }}
                )
                .then((resp) => {
                    this.filesKorektorFix = resp.data;
                });
        },
        sendFile() {
            if (this.file !== null) {
                let formData = new FormData();
                formData.append("file", this.file, this.file.name);
                formData.append("id", this.$route.params.id);
                formData.append("emmit", true);
                this.isLoaded = true;

                this.$axios
                    .post(`${this.url}api/uploads`, formData, { headers: { 'x-access-token': token }})
                    .then(() => {
                        this.$refs.form.reset();
                        this.isLoaded = false;
                        this.file = null;
                    });
            }
        },
        onAddNewFile() {
            const fileToUpload = document.querySelector("#file").files[0];
            this.file = fileToUpload;
        },
        getAttachToTask() {
            this.$axios
                .get(`${this.url}api/uploads/${this.$route.params.id}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    this.files = resp.data;
                });
        },
        deleteAttachment(id) {
            this.deleteAttachmentPopup = true;
            this.attachmentId = id;
        },
        deleteAttach(){
            this.$axios
                .delete(`${this.url}api/uploads/${this.attachmentId}`, { headers: { 'x-access-token': token }})
                .then(() => {
                    this.attachmentId = null;
                    this.deleteAttachmentPopup = false;
                })
                .catch((error) => {
                    throw error.response.data;
                });
        },
        getSingleTask() {
            this.$axios
                .get(`${this.url}api/tasks/${this.$route.params.id}`, { headers: { 'x-access-token': token }})
                .then((resp) => {
                    const {
                        name,
                        characters,
                        date,
                        description,
                        fullRate,
                        dateCp,
                        status,
                        copyRate,
                        copyLate,
                        orderType,
                        korektorId,
                        pmId,
                        correctorSend,
                        createdBy,
                        createdAt
                    } = resp.data;
                    this.copywriterId = resp.data.copywriterId
                        ? resp.data.copywriterId._id
                        : "";
                    this.dateCp = dateCp;
                    this.signs = characters;
                    this.date = date;
                    this.correctorSend = correctorSend;
                    this.korektorId = korektorId;
                    this.lateCopy = copyLate;
                    this.priceFull = fullRate;
                    this.name = name;
                    this.copyLate = copyLate;
                    this.status = status;
                    this.description = description;
                    this.pmId = pmId;
                    this.copyRate = copyRate;
                    this.orderType = orderType;
                    this.clientEmail = resp.data.clientId
                        ? resp.data.clientId.email
                        : "";
                    this.client = resp.data.clientId
                        ? resp.data.clientId.name
                        : "";
                    this.copywriter = resp.data.copywriterId
                        ? resp.data.copywriterId.name
                        : "";
                    this.createdAt = createdAt;
                    this.createdBy = createdBy;

                    document.title = this.name;

                    this.status == 'Ponownie poprawione' ? this.invoice = false : this.invoice = true
                })
                .catch((err) => console.log(err));
        },
        getLogs() {
            this.$axios.get(`${this.url}api/logs/${this.id}`, { headers: { 'x-access-token': token }}).then((resp) => {
                if (resp.data.length > 0) {
                    this.lastLog = resp.data[resp.data.length - 1];
                }
            });
        },
        endTaskKorektor() {

            // To do: popup
            // tak samo u copy tekst gotowy
            this.showRatingPopup = true;
            this.showCopyLate = true;
            let stat = "Zakończone";

            if (this.correctorSend == false) {
                stat = "Sprawdzone";
            }

            this.$axios.put(`${this.url}api/tasks/status`, {
                status: stat,
                id: this.$route.params.id,
                korektorId: this.korektorId,
                pmId: this.pmId,
                userId: this.userID,
                date: new Date().toISOString(),
            }, { headers: { 'x-access-token': token }});
        },
        endTaskKorektorChecked() {
            this.showRatingPopup = true;
            this.showCopyLate = true;
            let stat = "Ponownie poprawione";

            this.$axios.put(`${this.url}api/tasks/status`, {
                status: stat,
                id: this.$route.params.id,
                korektorId: this.korektorId,
                pmId: this.pmId,
                userId: this.userID,
                date: new Date().toISOString(),
            }, { headers: { 'x-access-token': token }});
        },
        changedStatus(item) {

            if(item == "Zakończone"){
                this.showCopyLate = true;
            }
            if (item == "Zakończone" && !this.taskRate) {
                this.showRatingPopup = true;
                this.ratingFlag = true;
            }

            this.$axios.put(`${this.url}api/tasks/status`, {
                status: item,
                id: this.$route.params.id,
                korektorId: this.korektorId,
                pmId: this.pmId,
                userId: this.userID,
                date: new Date().toISOString(),
            }, { headers: { 'x-access-token': token }});
        },
    },
    created() {
        this.getLogs();
        this.getAttachToTask();
        this.getSingleTask();
        this.getAttachKorektor();
        this.getAttachKorektorFix();
        this.getRating();

        this.socket.on("changedStatus", () => {
            this.getLogs();
        });
        this.socket.on("newAttachAdded", () => {
            this.getAttachToTask();
        });
        this.socket.on("attachmentRemoved", () => {
            this.getAttachToTask();
        });

        this.socket.on("editedTask", () => {
            this.getSingleTask();
            this.getAttachToTask();
        });
        this.socket.on("attachmentRemoved", () => {
            this.getAttachToTask();
        });
    },
};
</script>

<style lang="scss">
.cursor-pointer {
    cursor: pointer;
}
.align-center-element {
    align-self: center;
}
.scroll {
    overflow-y: auto;
}
.single-task__flex {
    display: flex;
    justify-content: center;
    align-items: center;
    .custom-input-container {
        margin: 0 10px;
    }
}
.rating {
    margin-top: 40px;
    &__top {
        padding-top: 20px;
    }
}
</style>
