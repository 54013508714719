<template>
    <v-flex md1>
        <v-btn
            color="primary"
            fab
            class="mx-0"
            :small="small"
            @click="modal = true"
        >
            <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-dialog v-model="modal" max-width="600">
            <v-card>
                <v-form>
                    <v-card-title class="pb-0">
                        <span>Edytuj zadanie</span>
                        <span
                            @click="modal = !modal"
                            class="ml-auto cursor-pointer"
                            >X</span
                        >
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-text-field
                            v-model="name"
                            label="Nazwa"
                            clearable
                            :error-messages="nameErrors"
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-text-field>

                        <VueEditor
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                            v-model="description"
                            class="mb-4"
                        />

                        <v-text-field
                            v-model="characters"
                            label="Liczba znaków"
                            clearable
                            v-if="
                                role === 'administrator' ||
                                role === 'moderator' ||
                                role === 'korektor'
                            "
                            :error-messages="characterErrors"
                            @input="$v.characters.$touch()"
                            @blur="$v.characters.$touch()"
                        ></v-text-field>

                        <v-btn-toggle
                            v-model="orderType"
                            class="d-flex"
                            color="primary"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        >
                            <v-btn>
                                za 1000 zzs
                            </v-btn>
                            <v-btn>
                                za całość
                            </v-btn>
                        </v-btn-toggle>

                        <v-text-field
                            v-model="copyRate"
                            label="Stawka dla copywritera"
                            clearable
                            :error-messages="copyRateErrors"
                            @input="$v.copyRate.$touch()"
                            @blur="$v.copyRate.$touch()"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-text-field>

                        <v-text-field
                            v-model="priceFull"
                            label="Stawka właściwa"
                            clearable
                            :error-messages="priceErrors"
                            @input="$v.priceFull.$touch()"
                            @blur="$v.priceFull.$touch()"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-text-field>

                        <!-- corrector -->
                        <v-text-field
                            v-model="correctorRate"
                            label="Stawka dla korektora (za 1000 zzs)"
                            clearable
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-text-field>
                        
                        <v-select
                            label="Przypisz klienta"
                            :items="clients"
                            :item-value="'_id'"
                            :item-text="getDisplayName"
                            v-model="clientId"
                            attach
                            clearable
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-select>

                        <v-select
                            label="Przypisz copywritera"
                            :items="filtredCopy"
                            item-text="name"
                            item-value="_id"
                            v-model="copywriterId"
                            clearable
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-select>

                        <v-select
                            label="Przypisz project managera"
                            :items="filtredMod"
                            :item-text="'name'"
                            :item-value="'_id'"
                            v-model="pmId"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                            required
                            clearable
                        ></v-select>

                        <v-select
                            label="Przypisz korektora"
                            :items="filtredKorektor"
                            item-text="name"
                            item-value="_id"
                            v-model="korektorId"
                            clearable
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        ></v-select>
                        <v-menu
                            v-model="menu"
                            min-width="290px"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date"
                                    label="Deadline"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date" :first-day-of-week="1"></v-date-picker>
                        </v-menu>
                        <v-datetime-picker
                            timeIcon="clock"
                            label="Deadline dla copywritera"
                            v-model="dateCp"
                            v-if="
                                role === 'administrator' || role === 'moderator'
                            "
                            :timePickerProps="{
                                format: '24hr',
                            }"
                            :firstDayOfWeek="1"
                        >
                        </v-datetime-picker>
                    </v-card-text>

                    <p class="font-weight-regular pl-6" v-if="feedback">
                        {{ feedback }}
                    </p>

                    <v-card-actions class="ml-4">
                        <v-btn
                            color="primary"
                            @click="editTask"
                            :disabled="$v.$invalid"
                            :loading="isLoaded"
                        >
                            Edytuj
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { errorMsg } from "@/mixins/Validation";
const { required, decimal } = require("vuelidate/lib/validators");

const token = localStorage.getItem('user');

export default {
    name: "EditTask",
    mixins: [validationMixin, errorMsg],
    components: {
        VueEditor,
    },
    props: {
        id: {
            type: String,
        },
        small: {
            type: Boolean,
            default: false,
        },
        addingAttachment: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        role() {
            return this.$store.getters.getUserRole;
        },
        filtredCopy() {
            return this.copywriters
                .filter(
                    (copy) =>
                        (copy.role === "copywriter" ||
                            copy.email.toLowerCase() === "eter@eter.pl" ||
                            copy.email.toLowerCase() === "eter@eter.com") &&
                        !copy.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
        filtredMod() {
            return this.copywriters
                .filter(
                    (mod) =>
                        mod.role === "moderator" && !mod.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
        filtredKorektor() {
            return this.copywriters
                .filter(
                    (korektor) =>
                        korektor.role === "korektor" && !korektor.isBanned
                )
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
        },
    },
    data() {
        return {
            isLoaded: false,
            file: null,
            modal: false,
            name: null,
            status: "w trakcie",
            description: null,
            characters: null,
            orderType: null,
            correctorSend: null,
            correctorRate: null,
            priceCopy: null,
            priceFull: null,
            client: null,
            clientId: null,
            pmId: null,
            copywriter: null,
            copywriterId: null,
            korektorId: null,
            feedback: null,
            date: null,
            dateCp: null,
            tasks: [],
            clients: [],
            copywriters: [],
            menu: false,
            copyRate: null,
        };
    },
    validations: {
        name: {
            required,
        },
        copyRate: {
            decimal,
            required,
        },
        priceFull: {
            decimal,
            required,
        },
        characters: {
            decimal,
            required,
        },
    },
    methods: {
        getDisplayName(client) {
            return client.name || (client.firstName + ' ' + client.lastName);
        },
        getSingleTask() {
            this.$axios.get(`${this.url}api/tasks/${this.id}`, { headers: { 'x-access-token': token } }).then((resp) => {
                const {
                    name,
                    characters,
                    date,
                    description,
                    fullRate,
                    corectorRate,
                    dateCp,
                    copyRate,
                    orderType,
                    correctorSend,
                } = resp.data;
                this.dateCp = dateCp ? new Date(dateCp) : "";
                this.characters = characters;
                (this.date = date
                    ? new Date(date).toISOString().substr(0, 10)
                    : ""),
                    (this.priceFull =
                        characters !== 0 && orderType == 0
                            ? (fullRate / characters) * 1000
                            : fullRate);
                this.name = name;
                this.correctorSend = correctorSend;
                this.description = description;
                this.copyRate =
                    characters !== 0 && orderType == 0
                        ? (copyRate / characters) * 1000
                        : copyRate;
                this.orderType = orderType;
                this.correctorRate = corectorRate;
                this.client = resp.data.clientId ? resp.data.clientId.name : "";
                this.copywriter = resp.data.copywriterId
                    ? resp.data.copywriterId.name
                    : "";
                this.copywriterId = resp.data.copywriterId
                    ? resp.data.copywriterId._id
                    : "";
                this.pmId = resp.data.pmId
                    ? resp.data.pmId._id
                    : "";
                this.korektorId = resp.data.korektorId
                    ? resp.data.korektorId
                    : "";
                this.clientId = resp.data.clientId
                    ? resp.data.clientId._id
                    : "";
            });
        },
        editTask() {
            let copyRateEdited = 0;
            let fullRateEdited = 0;

            if (this.orderType == 0 && this.characters != 0) {
                // per 1000 characters

                copyRateEdited = (this.copyRate * this.characters) / 1000;
                fullRateEdited = (this.priceFull * this.characters) / 1000;
            } else {
                copyRateEdited = this.copyRate;
                fullRateEdited = this.priceFull;
            }
            this.isLoaded = true;
            this.$axios
                .put(`${this.url}api/tasks/`, {
                    name: this.name,
                    characters: this.characters,
                    date: this.date,
                    description: this.description,
                    fullRate: parseFloat(fullRateEdited),
                    dateCp: this.dateCp,
                    copyRate: parseFloat(copyRateEdited),
                    orderType: this.orderType,
                    clientId: this.clientId,
                    pmId: this.pmId,
                    korektorId: this.korektorId,
                    corectorRate: parseFloat(this.correctorRate),
                    copywriterId: this.copywriterId,
                    correctorSend: this.correctorSend,
                    id: this.id,
                }, { headers: { 'x-access-token': token } })
                .then(() => {
                    this.modal = false;
                    this.isLoaded = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getClients() {
            this.$axios.get(`${this.url}api/clients`, {
                headers: { 'x-access-token': token }
            }).then((resp) => {
                this.clients = resp.data;
            });
        },
        getCopywriters() {
            this.$axios.get(`${this.url}api/users`, {
                headers: { 'x-access-token': token }
            }).then((resp) => {
                this.copywriters = resp.data;
            });
        },
    },
    created() {
        this.getSingleTask();
        this.getCopywriters();
        this.getClients();
    },
};
</script>
