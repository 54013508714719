<template>
    <div>
        <v-dialog v-model="deleteAttachmentPopup" max-width="450px">
            <v-card>
                <v-card-title>
                    Czy na pewno chcesz usunąć załącznik?
                </v-card-title>
                <v-card-text>
                    <v-btn
                        color="green"
                        class="white--text mr-4"
                        @click.stop="deleteAttachmentSwitch"
                    >
                        TAK
                    </v-btn>
                    <v-btn
                        color="gray"
                        class="dark--text"
                        @click.stop="deleteAttachmentPopup = false"
                    >
                        NIE
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-flex class="mr-2 mb-3">
            <div>
                Załączniki copywritera
                <div
                        text
                        v-for="(attachment, index) in filesCopy"
                        :key="index"
                        class="wordbreak mb-3 mt-4 d-flex justify-center align-center" >

                        <a
                            @click="downloadFile(attachment)"
                            v-if="attachment.path" 
                        >
                            {{ attachment.originalName }}
                        </a>

                        <v-btn 
                            small 
                            class="ml-5"
                            @click="deleteAttachment(attachment._id, 'copy')"
                        >
                            X
                    </v-btn>
                </div>
            </div>

            <v-divider v-if="status == 'Do poprawki' || status == 'Poprawione'  || (getCurrentStatus == 'Zakończone' || getOldStatus == 'Poprawione')"></v-divider>

            <div v-if="status == 'Do poprawki' || status == 'Poprawione' || status == 'Ponownie poprawione' || (getCurrentStatus == 'Zakończone' || getOldStatus == 'Poprawione')"  class="mt-5">
                Poprawione załączniki copywritera
                <div
                        text
                        v-for="(attachment, index) in filesCopyFix"
                        :key="index"
                        class="wordbreak mb-3 mt-4 d-flex justify-center align-center" >
                        <a
                            @click="downloadFile(attachment)"
                            v-if="attachment.path" 
                        >
                            {{ attachment.originalName }}
                        </a>
                        <v-btn 
                            small 
                            class="ml-5"
                            @click="deleteAttachment(attachment._id, 'copy')"
                        >
                            X
                    </v-btn>
                </div>
            </div>
            <div>

            </div>
            <div v-if="status == 'Do poprawki' || status == 'Poprawione' || (getCurrentStatus == 'Zakończone' && getOldStatus == 'Poprawione')">
                <v-form
                    ref="formCopyFix"
                    class="d-flex align-baseline"
                    v-if="role === 'copywriter' || role === 'administrator' || role === 'moderator'"
                >
                <v-file-input
                    id="formCopyFix"
                    type="file"
                    v-on:change="onAddNewFileCopyFix"
                    label="Dodaj poprawione załącznik"
                ></v-file-input>
                    <v-btn 
                        :disabled="!this.fileCopyFix"
                        small 
                        class="ml-4"
                        :loading="isLoadedCopy"
                        @click="sendFileCopyFix">
                        Dodaj
                    </v-btn>
                </v-form>
            </div>
            <div v-else>
                <v-form
                    ref="formCopy"
                    class="d-flex align-baseline"
                    v-if="role === 'copywriter' || role === 'administrator' || role === 'moderator'"
                >
                <v-file-input
                    id="fileCopy"
                    type="file"
                    v-on:change="onAddNewFileCopy"
                    label="Dodaj załącznik"
                ></v-file-input>
                    <v-btn 
                        :disabled="!this.fileCopy"
                        small 
                        class="ml-4"
                        :loading="isLoadedCopy"
                        @click="sendFileCopy">
                        Dodaj
                    </v-btn>
                </v-form>
            </div>
        </v-flex>
        <v-divider></v-divider>
        <v-flex class="mt-3 ml-2">
            <div>
                Załączniki Korektora
                <div
                    text
                    v-for="(attachment, index) in filesKorektor"
                    :key="index"
                    class="mb-3 mt-4 wordbreak" row>
                        <a
                            @click="downloadFile(attachment)"
                            v-if="attachment.path" 
                        >
                            {{ attachment.originalName }}
                        </a>
                    <v-btn
                        v-if="role === 'korektor' || role === 'administrator' || role === 'moderator'"
                        small 
                        class="ml-5" 
                        @click="deleteAttachment(attachment._id, 'korektor')">
                        X
                    </v-btn>
                </div>
            </div>
            <div v-if="status == 'Do sprawdzenia' || status == 'Do poprawki' || status == 'Poprawione' || (getCurrentStatus == 'Zakończone' && getOldStatus == 'Poprawione')">
                <v-form 
					ref="formKorektor"
					class="d-flex align-baseline"
					v-if="role === 'korektor' || role === 'administrator' || role === 'moderator'"
					row
				>
					<v-file-input
						id="fileKorektor"
						type="file"
						v-on:change="onAddNewFileKorektor"
						label="Dodaj załącznik"
					></v-file-input>
					<v-btn 
						small 
						class="ml-4"  
						:disabled="!this.fileKorektor"
						@click="sendFileKorektor"
						:loading="this.isLoadedKorektor"
						>
							Dodaj
					</v-btn>
                </v-form>
            </div>
            <v-divider v-if="status == 'Do poprawki' || status == 'Poprawione'  || (getCurrentStatus == 'Zakończone' || getOldStatus == 'Poprawione')"></v-divider>
            <div v-if="status == 'Do poprawki' || status == 'Poprawione' || status == 'Ponownie poprawione'  || (getCurrentStatus == 'Zakończone' || getOldStatus == 'Poprawione')"  class="mt-5">
                Poprawione załączniki korektora
                <div
                    text
                    v-for="(attachment, index) in filesKorektorFix"
                    :key="index"
                    class="mb-3 mt-4 wordbreak" row>
                        <a
                            @click="downloadFile(attachment)"
                            v-if="attachment.path" 
                        >
                            {{ attachment.originalName }}
                        </a>
                    <v-btn
                        v-if="role === 'korektor' || role === 'administrator' || role === 'moderator'"
                        small 
                        class="ml-5" 
                        @click="deleteAttachment(attachment._id, 'korektor')">
                        X
                    </v-btn>
                </div>
            </div>
            <div v-if="status == 'Poprawione' || status == 'Ponownie poprawione' || status == 'Do poprawki' || status == 'Poprawione' || (getCurrentStatus == 'Zakończone' && getOldStatus == 'Poprawione')">
                <v-form 
					ref="formKorektorFix"
					class="d-flex align-baseline"
					v-if="role === 'korektor' || role === 'administrator' || role === 'moderator'"
					row
				>
					<v-file-input
						id="fileKorektorFix"
						type="file"
						v-on:change="onAddNewFileKorektorFix"
						label="Dodaj poprawiony załącznik"
					></v-file-input>
					<v-btn 
						small 
						class="ml-4"  
						:disabled="!this.fileKorektorFix"
						@click="sendFileKorektorFix"
						:loading="this.isLoadedKorektor"
						>
							Dodaj
					</v-btn>
                </v-form>
            </div>
           
        </v-flex>
    </div>
</template>

<script>
import io from 'socket.io-client';

const token = localStorage.getItem('user');

export default {
	name: 'Attachments',
    computed: {
        role(){
            return this.$store.getters.getUserRole;
		},
		getOldStatus(){
            let statusToReturn = '';
            if(this.oldStatus){
                statusToReturn =  this.oldStatus.fromStatus
            }else {
                statusToReturn = ''
            }
            return statusToReturn;
        },
        getCurrentStatus(){
            let statusToReturn = '';
            if(this.oldStatus){
                statusToReturn =  this.oldStatus.toStatus
            }else {
                statusToReturn = ''
            }
            return statusToReturn;
        }
    }, 
    props:{
        taksId: {
            type: String,
        },
        status: {
            type: String,
        },
        oldStatus: {
            type: Object,
        }
    },

    data(){
        return{
			isLoadedCopy: false,
			isLoadedKorektor: false,

            deleteId: null,
            switcher: null,

            socket: io(this.url),

            filesCopy: null,
            filesKorektor: null,
            fileCopy: null,
			fileKorektor: null,

			filesCopyFix: null,
            filesKorektorFix: null,
            fileCopyFix: null,
			fileKorektorFix: null,

            deleteAttachmentPopup: false
        }
    },
    methods: {
        async downloadFile(attachment) {
            try {
                const response = await this.$axios({
                    url: attachment.path,
                    method: 'GET',
                    responseType: 'blob',
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attachment.originalName);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.error(error);
                alert('Wystąpił błąd podczas pobierania pliku');
            }
        },
        getAttach(){
            this.$axios
            .get(`${this.url}api/uploads/${this.taksId}`, { headers: { 'x-access-token': token } })
            .then(resp =>{
                this.files = resp.data 
            })
        },
        onAddNewFileCopy(){
			const fileToUpload = document.querySelector('#fileCopy').files[0]
            this.fileCopy = fileToUpload
        },
        onAddNewFileCopyFix(){
			const fileToUpload = document.querySelector('#formCopyFix').files[0]
            this.fileCopyFix = fileToUpload
        },
        onAddNewFileKorektor(){
            const fileToUpload = document.querySelector('#fileKorektor').files[0]
            this.fileKorektor = fileToUpload
        },
        onAddNewFileKorektorFix(){
            const fileToUpload = document.querySelector('#fileKorektorFix').files[0]
            this.fileKorektorFix = fileToUpload
        },
        sendFileCopy(){
            if (this.fileCopy != null){
                let formData = new FormData();
				this.isLoadedCopy = true;
                formData.append('file', this.fileCopy);
                formData.append('id', this.$route.params.id);

                this.$axios.post(this.url + 'api/uploads/copy', formData, { headers: { 'x-access-token': token } })
                .then(() => {
					this.$refs.formCopy.reset()
					this.isLoadedCopy = false
					this.fileCopy = null
                })
            }
        },
        sendFileCopyFix(){
            if (this.fileCopyFix != null){
                let formData = new FormData();
				this.isLoadedCopy = true;
                formData.append('file', this.fileCopyFix);
                formData.append('id', this.$route.params.id);

                this.$axios.post(this.url + 'api/uploads/copy-fix', formData, { headers: { 'x-access-token': token } })
                .then(() => {
					this.$refs.formCopyFix.reset()
					this.isLoadedCopy = false
					this.fileCopy = null
                })
            }
        },
        sendFileKorektor(){
            if (this.fileKorektor != null){
                let formData = new FormData();
                formData.append('file', this.fileKorektor);
                formData.append('id', this.$route.params.id);
				this.isLoadedKorektor = true;
                this.$axios.post(this.url + 'api/uploads/korektor', formData, { headers: { 'x-access-token': token } })
                .then(() => {
                    this.$router.go()
					this.$refs.formKorektor.reset()
					this.isLoadedKorektor = false
					this.fileKorektor = null
                })
            }
            
        },
        sendFileKorektorFix(){
            if (this.fileKorektorFix != null){
                let formData = new FormData();
                formData.append('file', this.fileKorektorFix);
                formData.append('id', this.$route.params.id);
				this.isLoadedKorektor = true;
                this.$axios.post(this.url + 'api/uploads/korektor-fix', formData, { headers: { 'x-access-token': token } })
                .then(() => {
                    this.$router.go()
					this.$refs.formKorektorFix.reset()
					this.isLoadedKorektor = false
					this.fileKorektorFix = null

                })
            }
            
        },
        getAttachKorektor(){
            this.$axios
            .get(this.url + 'api/uploads/korektor/' + this.$route.params.id, { headers: { 'x-access-token': token } })
            .then(resp =>{ 
				this.filesKorektor = resp.data });
        },
        getAttachKorektorFix(){
            this.$axios
            .get(this.url + 'api/uploads/korektor-fix/' + this.$route.params.id, { headers: { 'x-access-token': token } })
            .then(resp =>{ 
				this.filesKorektorFix = resp.data });
        },
        getAttachCopy(){
            this.$axios
            .get(this.url + 'api/uploads/copy/' + this.$route.params.id, { headers: { 'x-access-token': token } })
            .then(resp =>{ 
                this.filesCopy = resp.data
                console.log(this.filesCopy)
            });
        },
        getAttachCopyFix(){
            this.$axios
            .get(this.url + 'api/uploads/copy-fix/' + this.$route.params.id, { headers: { 'x-access-token': token } })
            .then(resp =>{ this.filesCopyFix = resp.data });
        },
        deleteAttachment(id, switcher){
            this.deleteAttachmentPopup = true;
            this.deleteId = id;
            this.switcher = switcher;
        },
        deleteAttachmentSwitch(){
            console.log(this.deleteId);
            console.log(this.switcher);
            switch (this.switcher) {
                case 'copy':
                        this.$axios.delete(`${this.url}api/uploads/copy/${this.deleteId}`, { headers: { 'x-access-token': token } })
                        .then(() => {
                            this.deleteAttachmentPopup = false;
                            this.deleteId = null;
                        })
                        .catch((error) => {
                            throw error.response.data
                        })
                    break;
                case 'korektor':
                        this.$axios.delete(`${this.url}api/uploads/korektor/${this.deleteId}`, { headers: { 'x-access-token': token } })
                        .then(() => {
                            this.deleteAttachmentPopup = false;
                            this.deleteId = null;
                            this.$router.go();
                        })
                        .catch((error) => {
                            throw error.response.data
                        })
                    break;
            
                default:
                    break;
            }
            
        },
        deleteAttachmentKorektor(){
            
        },
    },
    created() {
        this.getAttachKorektor()
        this.getAttachCopy()
        this.getAttachCopyFix()
        this.getAttachKorektorFix()

        this.socket.on('newAttachAddedCopy', () => {
			this.getAttachCopy()
            this.getAttachKorektor()
            this.getAttachCopyFix()
            this.getAttachKorektorFix()

        });
        this.socket.on('newAttachAddedKorektor', () => {
			this.getAttachCopy()
            this.getAttachKorektor()
            this.getAttachCopyFix()
            this.getAttachKorektorFix()

        });
        this.socket.on('attachmentRemoved', () => {
            this.getAttachKorektor()
            this.getAttachCopy()
            this.getAttachCopyFix()
            this.getAttachKorektorFix()

        });
    },
}
</script>

<style lang="scss" scoped>
	.wordbreak { 
		word-break: break-all;
		a{
			word-break: break-all;
		}
	}
</style>