<template>
    <div id="comments" class="ml-auto">    
        <h2>Komentarze</h2>
        <div 
			v-for="(comment, index) in comments" 
			:key="index" 
			class="my-2 px-3 d-flex aling-center justify-center" >
            <Avatar 
				size="40" 
				:name='comment.name'
				noLink
                :avatar="comment.avatar"
				class="align-center-element"
            /> 
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="mb-1 text-left"><strong>{{comment.name}}</strong></v-list-item-title>
                    <v-card 
						max-width="700"
						flat  
						class="wordbreak pa-2 pl-0 text-left single-comment" 
						v-html="comment.content"
					>
					</v-card>
                    <v-list-item-subtitle  
						class="text-left caption"
					>  
						{{comment.date.split('T')[0]}} {{correctDate(comment.date)}} 
					</v-list-item-subtitle>
                </v-list-item-content>
                <v-btn
                    v-if="role === 'administrator' || role === 'moderator'"
                    small text @click.stop="modal = true"
                >
					X
				</v-btn>
            </v-list-item>
			<v-dialog
				v-model="modal"
				max-width="325px"
			>
				<v-card>
						<v-card-title>
							Na pewno usunąć komentarz?
						</v-card-title>
						<v-card-text>
							<v-btn
								color="red"
								class="white--text mr-4"
								@click.stop="deleteComment(comment._id)"
							>
								TAK
							</v-btn>
							<v-btn
								color="gray"
								class="dark--text"
								@click.stop="modal = false"
							>
								NIE
							</v-btn>
						</v-card-text>
				</v-card>
			</v-dialog>
        </div>
        <v-flex 
			row 
			height="500" 
			class="align-start py-3 px-0 pl-5 "
		>
			<v-card 
				flat 
				class="mx-auto mb-4 d-flex"
                style="width: 100%;"  
			>
				<v-card-text>
                    <VueEditor v-model="text" style="width: 100%; margin: 0 auto;" class="wordbreak" />
					<v-btn 
						:disabled="!isEmpty"
						class="mt-4 ml-auto mr-0" 
						@click="sendComment" 
						:loading="isLoaded"
					>
						Dodaj
					</v-btn>

				</v-card-text>
				
			</v-card>
        </v-flex> 
    </div>
</template>


<script>
import { VueEditor } from "vue2-editor";
import Avatar from "@/components/molecules/Avatar.vue";
import io from 'socket.io-client';

const token = localStorage.getItem('user');

export default {
    data() {
        return {
			isLoaded: false,
            text: '',
            socket: io(this.url),
            comments: [],
			modal: false,
        }
    },
    computed: {
		isEmpty(){
			return this.text.length > 0;
		},
        role(){
            return this.$store.getters.getUserRole;
		},
		userID(){
			return this.$store.getters.getUserID;
		},
		userMail(){
			return this.$store.getters.getUserMail;
		},
	
    },
    components: {
		VueEditor,
        Avatar
    },
    methods: {
		currentFullDate(){
			return new Date().toISOString()
		},
		correctDate(date){
			const newDate = new Date(date);
			return `${newDate.getHours()}:${newDate.getMinutes() < 9 ?  '0' + newDate.getMinutes() : newDate.getMinutes()}`
		},
        sendComment(){
			this.isLoaded = true;
            this.$axios.post(`${this.url}api/comments`,{
                content : this.text, 
				userMail: this.userMail,
                taskId: this.$route.params.id, 
				date: this.currentFullDate(),
				id: this.userID
				}, { headers: { 'x-access-token': token } })
                .then((resp)=> {
                    const { success } = resp.data;
                    if(success){
						this.isLoaded = false;
                        this.text = '';
                    }
            })
        },
        getComments(){
                this.$axios.get(`${this.url}api/comments/${this.$route.params.id}`, { headers: { 'x-access-token': token } })
                .then((resp)=> {
                    this.comments = resp.data.comment
            })
        },
        deleteComment(id){
			this.$axios.delete(`${this.url}api/comments/${id}`, { headers: { 'x-access-token': token } })
				.then(this.modal = false)
				.catch((error) => {
					throw error
				})
        }
    },
    created() {
        this.getComments();
        this.socket.on('commentAdded', () => {
            this.getComments()
        })
        this.socket.on('commentRemoved', () => {
            this.getComments()
        })
    },
}
</script>

<style lang="scss" scoped>
	::v-deep .wordbreak {
        max-width: 1000px;  /* Maksymalna szerokość */
        width: 100%;
        // max-width: 1000px;
		word-wrap: break-word;
		a{
			word-break: break-word;
		}
	}
    ::v-deep .single-comment{
        p img{
            width: 100% !important;
        }
    }
    ::v-deep .ql-container{
        max-width: 1000px !important;
        width: 100% !important;
    }
</style>