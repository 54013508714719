var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{attrs:{"md1":""}},[_c('v-btn',{staticClass:"mx-0",attrs:{"color":"primary","fab":"","small":_vm.small},on:{"click":function($event){_vm.modal = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',[_vm._v("Edytuj zadanie")]),_c('span',{staticClass:"ml-auto cursor-pointer",on:{"click":function($event){_vm.modal = !_vm.modal}}},[_vm._v("X")])]),_c('v-card-text',{staticClass:"pb-0"},[(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-text-field',{attrs:{"label":"Nazwa","clearable":"","error-messages":_vm.nameErrors},on:{"input":function($event){return _vm.$v.name.$touch()},"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('VueEditor',{staticClass:"mb-4",model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}):_vm._e(),(
                            _vm.role === 'administrator' ||
                            _vm.role === 'moderator' ||
                            _vm.role === 'korektor'
                        )?_c('v-text-field',{attrs:{"label":"Liczba znaków","clearable":"","error-messages":_vm.characterErrors},on:{"input":function($event){return _vm.$v.characters.$touch()},"blur":function($event){return _vm.$v.characters.$touch()}},model:{value:(_vm.characters),callback:function ($$v) {_vm.characters=$$v},expression:"characters"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-btn-toggle',{staticClass:"d-flex",attrs:{"color":"primary"},model:{value:(_vm.orderType),callback:function ($$v) {_vm.orderType=$$v},expression:"orderType"}},[_c('v-btn',[_vm._v(" za 1000 zzs ")]),_c('v-btn',[_vm._v(" za całość ")])],1):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-text-field',{attrs:{"label":"Stawka dla copywritera","clearable":"","error-messages":_vm.copyRateErrors},on:{"input":function($event){return _vm.$v.copyRate.$touch()},"blur":function($event){return _vm.$v.copyRate.$touch()}},model:{value:(_vm.copyRate),callback:function ($$v) {_vm.copyRate=$$v},expression:"copyRate"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-text-field',{attrs:{"label":"Stawka właściwa","clearable":"","error-messages":_vm.priceErrors},on:{"input":function($event){return _vm.$v.priceFull.$touch()},"blur":function($event){return _vm.$v.priceFull.$touch()}},model:{value:(_vm.priceFull),callback:function ($$v) {_vm.priceFull=$$v},expression:"priceFull"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-text-field',{attrs:{"label":"Stawka dla korektora (za 1000 zzs)","clearable":""},model:{value:(_vm.correctorRate),callback:function ($$v) {_vm.correctorRate=$$v},expression:"correctorRate"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-select',{attrs:{"label":"Przypisz klienta","items":_vm.clients,"item-value":'_id',"item-text":_vm.getDisplayName,"attach":"","clearable":""},model:{value:(_vm.clientId),callback:function ($$v) {_vm.clientId=$$v},expression:"clientId"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-select',{attrs:{"label":"Przypisz copywritera","items":_vm.filtredCopy,"item-text":"name","item-value":"_id","clearable":""},model:{value:(_vm.copywriterId),callback:function ($$v) {_vm.copywriterId=$$v},expression:"copywriterId"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-select',{attrs:{"label":"Przypisz project managera","items":_vm.filtredMod,"item-text":'name',"item-value":'_id',"required":"","clearable":""},model:{value:(_vm.pmId),callback:function ($$v) {_vm.pmId=$$v},expression:"pmId"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-select',{attrs:{"label":"Przypisz korektora","items":_vm.filtredKorektor,"item-text":"name","item-value":"_id","clearable":""},model:{value:(_vm.korektorId),callback:function ($$v) {_vm.korektorId=$$v},expression:"korektorId"}}):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-menu',{attrs:{"min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Deadline","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}],null,false,2457162327),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),(
                            _vm.role === 'administrator' || _vm.role === 'moderator'
                        )?_c('v-datetime-picker',{attrs:{"timeIcon":"clock","label":"Deadline dla copywritera","timePickerProps":{
                            format: '24hr',
                        },"firstDayOfWeek":1},model:{value:(_vm.dateCp),callback:function ($$v) {_vm.dateCp=$$v},expression:"dateCp"}}):_vm._e()],1),(_vm.feedback)?_c('p',{staticClass:"font-weight-regular pl-6"},[_vm._v(" "+_vm._s(_vm.feedback)+" ")]):_vm._e(),_c('v-card-actions',{staticClass:"ml-4"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.$v.$invalid,"loading":_vm.isLoaded},on:{"click":_vm.editTask}},[_vm._v(" Edytuj ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }