<template>
    <v-card class='pa-2'>
        <v-list-item two-line v-if="createdBy">
            <v-list-item-content>
                <v-list-item-subtitle class="mb-1 text-left">
                    {{ createdAt.split('T')[0] }} {{ correctDate(createdAt) }}
                </v-list-item-subtitle>
                <v-list-item-title class="mb-1 text-left">
                    Użytkownik  
                    <span class="indigo--text">{{ createdBy }}</span> 
                    stworzył zadanie.
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        
        <div v-if="logs">
            <div 
                class='my-4'
                v-for="(log, index) in logs" 
                :key='index'
            >
                <v-list-item two-line >
                    <v-list-item-content>
                        <v-list-item-subtitle class="mb-1 text-left">
                            {{log.date.split('T')[0]}} {{correctDate(log.date)}}
                        </v-list-item-subtitle>
                        <v-list-item-title class="mb-1 text-left">
                            Użytkownik  
                            <span class="indigo--text">{{log.user}}</span> 
                            zmienił status zadania z 
                            <StatusChip :status="log.fromStatus" /> 
                            na 
                            <StatusChip :status="log.toStatus" />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </div>
    </v-card>
</template>

<script>
import io from 'socket.io-client';
import StatusChip from '@/components/molecules/StatusChip';

const token = localStorage.getItem('user');

export default {
	name: "Logs",
	components: {
		StatusChip
	},
    data(){
        return {
            socket: io(this.url),
            logs: null
        }
    },
    props: {
        id: String,
        createdAt: String,
        createdBy: String
    },
    methods: {
		correctDate(date){
			const newDate = new Date(date);
			return `${newDate.getHours()}:${newDate.getMinutes() < 9 ?  '0' + newDate.getMinutes() : newDate.getMinutes()}`
		},
        getLogs(){
            this.$axios.get(`${this.url}api/logs/${this.id}`, { headers: { 'x-access-token': token } })
                .then(resp => this.logs = resp.data)
        },
    },
    created() {
        this.getLogs();
        this.socket.on('changedStatus', () => {
            this.getLogs()
        });
    },
    
}
</script>